.Game {
  padding: 10px 5px 10px 5px;
}
.Loading {
  position: fixed;
  top: 100;
  left: 0;
  z-index: 999;
  width: 100%;
}
