.Login {
  padding: 10px 5px 10px 5px;
}
.login-form {
  max-width: 400px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}
