.Profile {
  padding: 10px 10px 10px 10px;
}

.ProfileLine {
  padding: 0px 0px 10px 0px;
}

.ProfileButton {
  margin: 0px 10px 0px 0px;
  width: 100px;
}
