.Home {
  padding: 10px 5px 10px 5px;
}

.HomeNoAuth {
  padding: 10px 5px 10px 5px;
}
.HomeSearch {
  margin: 0px 0px 0px 5px;
}
.HomeCard {
  margin: 5px 5px 5px 5px;
}

.Loading {
  position: fixed;
  top: 100;
  left: 0;
  z-index: 999;
  width: 100%;
}
