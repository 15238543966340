.navbar {
  background-color: #bcd7bc;
  padding: 0px 10px 0px 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,.3);
}

.Logo {
  font-size: 3em;
  font-family: 'Pacifico', cursive;
  color: #505050;
}

.Logo a {
    color: inherit;
    text-decoration: none;
}

.Logo a:hover {
    color: inherit;
    text-decoration: underline;
}

body {
  background-color: #E3F0DE;
}
